@import "styles/abstracts/index";
.wrapper {
  @include xxl-down() {
    padding: 25px 40px;
  }

  @include lg-down() {
    padding: 20px 33px;
  }
}

.logOut {
  font-size: 1.25rem;

  @include xxl-up() {
    font-size: 1.5625rem;
  }
}

.icon {
  font-size: 1.5rem;

  @include xxl-up() {
    font-size: 2rem;
  }
}
