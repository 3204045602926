@import "styles/abstracts/index";
.header {
  padding: 1.875rem 20px;

  & a {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    height: 41.59px;
  }
}
