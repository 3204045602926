@import "styles/abstracts/index";
.promoCard {
  background: var(--purple-light);
  position: relative;
}

.title {
  color: var(--purple-dark);
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 103%;
  max-width: 40%;
  font-family: var(--font-poppins);
}

.promoText {
  color: var(--purple-dark);
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 155%;
  font-family: var(--font-poppins);
}

.text {
  font-size: 1.25rem;
  line-height: 130%;
  color: var(--black-light);
  max-width: 70%;
}

.image {
  top: -23px;
  right: 35px;
  position: absolute;
}
