@import "styles/abstracts/index";
.triggerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  position: fixed;
  z-index: $zMax;
  right: 0;
  padding: 10px 3px;
  top: 50%;
  writing-mode: tb-rl;
  transform: scale(-1, -1) translateY(50%);
  font-size: 1.25rem;
  font-family: var(--font-popppins);
  cursor: pointer;
  transition: all 0.3s ease;
  background: var(--pink);
  color: var(--white);

  @include md-down() {
    font-size: 1rem;
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 20px;
    background: var(--pink);
    clip-path: polygon(0 100%, 100% 0, 0 0);
    transform: translateY(100%);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20px;
    background: var(--pink);
    transform: translateY(-100%);
    clip-path: polygon(0 100%, 0 0, 100% 100%);
  }

  &.active {
    color: var(--black);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: var(--white);
    transform: scale(-1, -1) translateY(50%);
    box-shadow: 0px 4px 26px rgba(112, 144, 176, 0.17);

    &::after,
    &::before {
      display: none;
    }
  }
}

.contentWrapper {
  background: var(--grey-medium);
  border-radius: 30px;

  .title {
    font-weight: 600;
    font-size: 1rem;
    line-height: 230%;
    border-bottom: 1px solid var(--black-dark);
  }

  .section {
    padding: 15px 17px;
  }

  .proTools {
    @extend .section;
    background: var(--blue-light);
    border-radius: 30px;
  }

  .content {
    // Calculated manually
    @media only screen and (max-height: 650px) {
      max-height: 150px;
      overflow-y: auto;
    }
  }

  .item {
    padding: 5px;
    font-weight: 500;
    font-size: 0.875rem;
    color: var(--blue);
    transition: all 0.3s ease;
    border-radius: var(--border-radius-sm);

    &:hover {
      background-color: var(--primary);
      color: var(--black);
    }
  }
}
