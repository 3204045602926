@import "styles/abstracts/index";
.title {
  font-weight: 700;
  line-height: 96%;
  letter-spacing: -0.5px;
  font-size: 3rem;

  color: var(--black-dark);

  @include xl-down() {
    font-size: 2.75rem;
  }

  @include lg-down() {
    font-size: 2.5rem;
  }

  @include xxl-up() {
    font-size: 3.75rem;
  }
}
