@import "styles/abstracts/index";
.menu {
  overflow: hidden;
  position: relative;

  .menu {
    max-height: 0;
    margin-left: 40px;
    transition: all 0.2s ease-out;

    &:before {
      position: absolute;
      background-color: var(--grey-dark);
      width: 0.2px;
      content: ' ';
      height: 100%;
      top: -20px;
      bottom: 0;
    }
  }

  &.open.menu {
    max-height: 2000px; // max abstract height for animation
    transition: all 0.3s ease-in;
  }
}

.menuItem {
  text-decoration: none;
  display: flex;
  gap: 20px;
  align-items: center;
  background-color: var(--white);
  padding: 0.5rem 1.8rem;
  cursor: pointer;
  color: var(--black-dark);
  font-size: 1.5rem;
  border-radius: var(--border-radius-md);
  font-weight: normal;
  margin-bottom: 20px;

  &:hover {
    background-color: rgba(122, 122, 122, 0.07);
  }

  & svg {
    font-size: 1.8rem;
  }

  &.active {
    background-color: rgba(173, 174, 255, 0.14);
    color: var(--blue-dark);
    font-weight: bold;
  }
}

.menu.sm .menuItem {
  margin-bottom: 10px;
  font-size: 1.1rem;
}

.menu .menu .menuItem {
  color: var(--grey-dark);
  padding: 0.5rem 1rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  &.active {
    border-left: 2px solid var(--blue-dark);
    color: var(--blue-dark);
  }
}
