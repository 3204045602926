@import "styles/abstracts/index";
.wrapper {
  // height: 100%;

  .header {
    transition: all 0.2s ease-in-out;

    &.transparent {
      background: transparent;
      transition: all 0.2s ease-in-out;
    }
  }

  .main {
    position: relative;

    &:after {
      position: absolute;
      content: ' ';
      z-index: -10;
      background: var(--dashboard-bg);
      transform: rotate(45deg);
      border-radius: 60px;
      width: 1000px;
      height: 1000px;
      top: -560px;
      left: -200px;

      @include xl-up() {
        width: 1200px;
        height: 1200px;
        top: -660px;
        left: -250px;
      }

      @include xxl-up() {
        width: 1600px;
        height: 1600px;
        top: -960px;
        left: -380px;
      }

      @include lg-down() {
        width: 600px;
        height: 600px;
        top: -360px;
        left: -180px;
      }

      @include md-down() {
        width: 400px;
        height: 400px;
        top: -260px;
        left: -100px;
      }
    }
  }
}

.insideWrapper {
  margin: 5rem 0;
}

.innerWrapper {
  @include lg-down() {
    gap: 30px;
  }
}

.leftSide {
  flex: 0 0 365px;

  @include lg-down() {
    width: unset;
    gap: 30px;
  }
}

.contentWrapper {
  @include lg-down() {
    flex-direction: column-reverse;
  }
}

.content {
  width: 100%;
  flex: 1;
  overflow: auto;
}